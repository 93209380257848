

























import { Component, Vue } from 'vue-property-decorator'
import { Sprint } from '@/models/Sprint.model'
import { SprintService } from '@/main/sprint/services/SprintService'
import BooleanIcon from '@/components/shared/icons/BooleanIcon.vue'
import router from '@/router'

@Component({
  components: { BooleanIcon }
})
export default class SprintListView extends Vue {
  initialLoadingDone = false
  loading = false

  private sprints: Sprint[] = []
  private headers = [
    {
      text: 'Name',
      value: 'name',
      sortable: false,
      width: '15%'
    },
    {
      text: 'Ziel',
      value: 'goal',
      sortable: false
    },
    {
      text: 'Zeitraum',
      value: 'timeframe',
      sortable: false,
      width: '25%'
    },
    { text: 'Status', value: 'active', sortable: false, width: '10%' }
  ]

  openSprint(sprint: Sprint) {
    router.push({ path: sprint.iri() })
  }

  async created() {
    this.loading = true
    this.sprints = await SprintService.getSprints(this.$store.state.product.currentProduct, 1)
    this.loading = false
    this.initialLoadingDone = true
  }
}
