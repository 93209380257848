






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SprintListView extends Vue {
  @Prop()
  private active!: boolean
}
